import axios from 'axios';
//用户服务器列表
let userlist = (data = {}) => axios.get('/api/v1/order/list', { params:data });
//用户矿机详情
let kuangInfo = (data = {}) => axios.get('/api/v1/order/detail/?OrderId='+data.id, {params:{}});
//托管列表
let trusteeshiplist = (data = {}) => axios.get('/api/v1/trusteeship/list', { params:data });
//托管详情
export default {
	userlist,
	kuangInfo,
	trusteeshiplist,
}
import axios from "axios"
//数据中心列表
let machinelist = (data= {}) => axios.get('/api/v1/pool/list', { params:data });
//pps列表
let ppslist = (data= {}) => axios.get('/api/v1/pool/pps', { params:data });
//购买
let buy = (data= {}) => axios.post('/api/v1/order/buy', data);
//上传图片
let ImageUpload = (data= {}) => axios.post('/api/v1/upload/payment',data);
//上传付款凭证
let pay = (data= {}) => axios.post('/api/v1/order/pay', data);
//购买详情
let buyInfo = (data = {}) => axios.get('/api/v1/order/detail/?OrderId='+data.id, {params:{}});
//购买托管费
let buytrusteeship = (data= {}) => axios.post('/api/v1/trusteeship/buy', data);

export default {
	machinelist,
	buy,
	pay,
	ImageUpload,
	ppslist,
	buyInfo,
	buytrusteeship,
}
import axios from "axios"
//基本信息
let info = (data= {}) => axios.get('/api/v1/my/profile', { params:data });
//认证
let realname = (data = {}) => axios.post('/api/v1/my/identify', data);
//上传图片
let ImageUpload = (data= {}) => axios.post('/api/v1/upload/id',data);
//修改密码
let password = (data = {}) => axios.post('/api/v1/my/change-passwrd', data);
export default {
	info,
	password,
	realname,
	ImageUpload,
}